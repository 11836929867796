import React from "react"

import Layout from "../components/Layout/layout"
import SEO from "../components/seo"
import Button from "../components/Elements/Button"
import { styled } from "linaria/react"

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  > h1 {
    font-size: 150px;
    text-align: center;
    font-weight: 600;
    margin-bottom: 70px;
    margin-top: 100px;
  }
  > p {
    margin-bottom: 150px;
    font-size: 35px;
    font-weight: 300;
  }
`

const Horizontal = styled.div`
  display: flex;
  > * {
    margin-right: 50px;
  }

  > *:last-child {
    margin-right: 0;
  }
`
const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Content>
      <h1>404</h1>
      <p>You just found a page that doesn&#39;t exist... the sadness.</p>
      <Horizontal>
        <Button
          text="Go Home"
          link="/"
          color="#242424"
          bgColor="#fff"
          border="1px solid #242424"
          weight="500"
        />
        <Button text="Stop The Sadness" link="/blog/" />
      </Horizontal>
    </Content>
  </Layout>
)

export default NotFoundPage
